<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>主线池配置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always">
      <div class="title">信息筛选</div>
      <div class="search-content">
        <div style="display:flex;">
          <div style="display:flex;align-items:center;">
            <div style="width:150px;">股票名称</div>
            <el-input v-model.trim="stockName" clearable placeholder="请输入股票名称" />
          </div>
          <div style="display:flex;align-items:center;margin-left:80px;">
            <div style="width:75px;">添加日期</div>
            <el-date-picker value-format="YYYY-MM-DD" v-model="publishDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="search-btn" @click="search">筛选</div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">主线股票池列表</div>
        <div class="header__right">
          <div class="deleteAll" @click="deleteAll">全部删除</div>
          <div class="add_btn" @click="addPolicy">+ 批量增加</div>
        </div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333'}" :cell-style="{'text-align': 'center',color: '#333'}" style="width: 100%">
          <el-table-column type='index' width='120' label="序号" />
          <el-table-column prop="stockName" label="股票名称" />
          <el-table-column prop="stockCode" label="股票代码" />
          <el-table-column prop="createTime" label="添加时间" />
          <el-table-column prop="createMan" label="添加人" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="delStock(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog title="批量增加股票" v-model="dialogVisible" :width="720">
      <el-form ref="form" label-width="180px" label-position="left" class="stock-form">
        <el-form-item label="上传股票列表">
          <div style="display:flex;width:100%">
            <div style="marginRight:30px">
              <el-upload class="upload-demo" accept='.xlsx,.xls' :before-upload="beforeUpload" :http-request="upload" action :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                <el-button size="small" style="padding:0;color: #0f40f5;background:transparent;border:none;fontSize:14px;fontWeight:bold">
                  {{fileList.length===0?'点击上传':'重新上传'}}</el-button>
                <!-- <el-button size="small" style="padding:0;color: #0f40f5;background:transparent;border:none;fontSize:14px;fontWeight:bold">点击上传</el-button> -->
              </el-upload>
            </div>
            <div style="color: #0f40f5;fontSize:14px;fontWeight:bold;">
              <a :href=baseUrl download='stock.xlsx' target="view_window" class="link">下载模板</a>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="股票数量(成功/失败)">
          <div>{{successStockList.length}}/{{failStockList.length}}</div>
        </el-form-item>
        <el-form-item label="股票清单">
          <div class="stockMenu">
            <div class="changeBtn">
              <div :class="{common:status===item}" @click="changeBtn(item)" v-for="(item,index) in changeBtns" :key="index">{{item}}</div>
            </div>
            <div>
              <el-table :height="stockList.length>4?'249':49+40*stockList.length" :data="stockList" border style="width: 100%">
                <el-table-column type="index" label="序号" width="150">
                </el-table-column>
                <el-table-column prop="stockCode" label="股票代码" width="150">
                </el-table-column>
                <el-table-column prop="stockName" label="股票名称" width="150">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="confirm" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
const http = axios.create({
  baseURL: "/api",
  headers: {
    "api-version": "1.0.0",
    "Content-Type": "multipart/form-data",
    'token': localStorage.getItem('user_token'),
  }
});
http.interceptors.request.use(res => {
  res.headers.token = localStorage.getItem('user_token');
  return res;
});
const baseUrl = location.protocol + "//" + location.host + '/static/stock.xlsx'
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      stockName: '',
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      stockList: [],
      successStockList: [],
      failStockList: [],
      total: 0,
      dialogVisible: false,
      fileList: [],
      changeBtns: ['处理成功', '处理失败'],
      status: '处理成功'
    })

    onMounted(() => {
      getList()
    })
    // 上传
    const upload = (param) => {
      const formData = new FormData();
      formData.append("file", param.file)
      handleUpload(formData)
    }
    const beforeUpload = (file, fileList) => {
      const isExcel =
        file.name.split(".")[1] === "xlsx" ||
        file.name.split(".")[1] === "xls"
      if (!isExcel) {
        ElMessage({
          message: "只能上传xlsx、xls文件",
          type: "error",
        });
      }
      return isExcel;
    };
    const handleRemove = (file, fileList) => {
    };
    const handlePreview = (file) => {
    };
    const handleExceed = (files, fileList) => {
      state.fileList = files
      const formData = new FormData();
      formData.append("file", files[0])
      handleUpload(formData, files)
    };
    const handleUpload = (formData, files) => {
      http.post("/cts/back/mainLineStock/uploadStock", formData).then((res) => {
        if (res.data.resultState === "200") {
          state.status = '处理成功'
          state.stockList = res.data.data.successDo
          state.successStockList = res.data.data.successDo
          state.failStockList = res.data.data.unsuccessfulDo
        } else {
          ElMessage({
            message: res.data.msg,
            type: "error",
          });
          state.fileList = []
          state.stockList = []
          state.successStockList = []
          state.failStockList = []
        }
      })
        .catch((err) => { });
    }
    const beforeRemove = (file, fileList) => {
      // ElMessageBox.alert(`确定移除 ${file.name}？`, "提示", {
      //   confirmButtonText: "确定",
      //   type: "warning",
      // }).then(() => {
      state.stockList = []
      state.successStockList = []
      state.failStockList = []
      // }).catch(() => { });
    }
    const search = () => {
      getList()
    }

    const getList = () => {
      let data = {
        endDate: state.publishDate ? state.publishDate[1] || '' : '',
        page: {
          pageNo: state.currentPage,
          pageSize: state.pageSize
        },
        startDate: state.publishDate ? state.publishDate[0] || '' : '',
        stockName: state.stockName
      }
      proxy.$post('/cts/back/mainLineStock/page', data).then((res) => {
        if (res.resultState === '200') {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      }).catch((err) => {

      });
    }

    const confirm = () => {
      if (state.successStockList.length === 0) {
        ElMessage({
          message: "请上传处理成功的股票清单",
          type: "error",
        });
        return
      }
      proxy.$post('/cts/back/mainLineStock/add', state.successStockList).then((res) => {
        if (res.resultState === '200') {
          getList();
          state.dialogVisible = false
        }
      }).catch((err) => {

      });
    }

    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    }

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    }

    const addPolicy = () => {
      state.dialogVisible = true
      state.fileList = []
      state.stockList = []
      state.successStockList = []
      state.failStockList = []
    }
    const changeBtn = (item) => {
      state.status = item
      if (item === '处理成功') {
        state.stockList = state.successStockList
      } else {
        state.stockList = state.failStockList
      }
    }

    const delStock = (data) => {
      ElMessageBox.alert(`确定删除该股票？`, "提示", {
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        let params = `?stockCode=${data.stockCode}`
        proxy.$get('/cts/back/mainLineStock/singleDelete' + params).then((res) => {
          if (res.resultState === '200') {
            getList();
          }
        }).catch((err) => {

        });
      }).catch(() => { });
    }
    const deleteAll = (data) => {
      if (state.tableData.length === 0) return
      ElMessageBox.alert(`确定删除全部股票？`, "提示", {
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        proxy.$get('/cts/back/mainLineStock/deleteAll').then((res) => {
          if (res.resultState === '200') {
            getList();
          }
        }).catch((err) => {

        });
      }).catch(() => { });
    }

    const formatterTime = (row, column) => {
      let time = row.releaseDate;
      let month = '', date = '';
      if (time[1] < 10) {
        month = `0${time[1]}`
      } else {
        month = `${time[1]}`
      }
      if (time[2] < 10) {
        date = `0${time[2]}`
      } else {
        date = `${time[2]}`
      }

      return `${time[0]}-${month}-${date}`
    }

    return {
      ...toRefs(state),
      search,
      handleSizeChange,
      handleCurrentChange,
      addPolicy,
      delStock,
      formatterTime,
      beforeUpload,
      handleRemove,
      handlePreview,
      handleExceed,
      beforeRemove,
      upload,
      baseUrl,
      handleUpload,
      changeBtn,
      confirm,
      deleteAll
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    font-weight: bold;
    // &__title {
    //   font-weight: bold;
    // }
    &__right {
      display: flex;
      justify-content: space-between;
      .deleteAll {
        line-height: 34px;
        color: #0f40f5;
        cursor: pointer;
      }
      .add_btn {
        background: rgb(56, 162, 138);
        width: 110px;
        margin-left: 40px;
        padding: 8px 0;
        border-radius: 6px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
.stock-form {
  a.link,
  a:visited,
  a:link {
    color: #0f40f5;
    text-decoration: none;
  }
  a:hover {
    color: #0f40f5;
  }
  .stockMenu {
    .changeBtn {
      width: 200px;
      display: flex;
      border: 1px solid #ccc;
      text-align: center;
      margin-bottom: 20px;
      div {
        width: 100px;
        box-sizing: border-box;
        cursor: pointer;
        &.common {
          background-color: #93d2f3;
          color: #fff;
        }
      }
      div:first-child {
        border-right: 1px solid #ccc;
      }
    }
  }
}
::v-deep .el-dialog__footer {
  text-align: left;
}
</style>